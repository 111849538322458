/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  vertical-align: baseline; }

img {
  display: block; }

button {
  outline: none; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
  display: block; }

/* unvisited link */
a:link, a:visited, a:hover, a:active {
  color: #ffffff;
  text-decoration: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* custom */
a {
  color: #ffffff;
  text-decoration: none;
  -webkit-backface-visibility: hidden; }

i {
  font-style: normal; }

li {
  list-style: none; }

body {
  width: 100%;
  position: relative;
  font-family: Arial; }

::-webkit-scrollbar {
  display: none; }

.line-clamp-six {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6; }

.progress {
  background: #3c3c3c;
  width: 5.250rem;
  height: 0.333rem;
  overflow: hidden;
  border-radius: 0.167rem;
  -webkit-appearance: none; }

::-ms-fill {
  background: #fafafa; }

::-moz-progress-bar {
  background: #fafafa; }

::-webkit-progress-bar {
  background: #3c3c3c; }

::-webkit-progress-value {
  background: #fafafa;
  border-radius: 0.167rem;
  overflow: hidden; }

.homeLogo {
  width: 100%; }

button {
  border: none;
  white-space: nowrap; }

#aboutUs > .centent_box {
  width: 100%;
  padding-top: 0.7rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../static/aboutUs/aboutUs_banner@2x.jpg");
  padding-bottom: 0.8rem; }
  #aboutUs > .centent_box .centent_box_child {
    padding-left: 1.46rem; }
    #aboutUs > .centent_box .centent_box_child:nth-of-type(1) {
      padding-top: 1.2rem;
      padding-bottom: 0.82rem; }
      #aboutUs > .centent_box .centent_box_child:nth-of-type(1) .centent_box_child_text {
        animation-delay: 0.5s; }
    #aboutUs > .centent_box .centent_box_child:nth-of-type(2) .logo_title {
      animation-delay: 1s; }
    #aboutUs > .centent_box .centent_box_child:nth-of-type(2) .centent_box_child_text {
      animation-delay: 1.5s; }
    #aboutUs > .centent_box .centent_box_child:nth-of-type(2) .logo_title .logo {
      width: 0.8rem;
      height: 0.8rem; }
    #aboutUs > .centent_box .centent_box_child .logo_title {
      display: flex;
      align-items: center; }
      #aboutUs > .centent_box .centent_box_child .logo_title .logo {
        width: 1.85rem;
        height: 0.64rem;
        margin-right: 0.14rem; }
      #aboutUs > .centent_box .centent_box_child .logo_title .title span {
        display: block; }
      #aboutUs > .centent_box .centent_box_child .logo_title .title .name {
        font-size: 0.29rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #ffffff; }
      #aboutUs > .centent_box .centent_box_child .logo_title .title .describe {
        font-size: 0.16rem;
        font-weight: normal;
        letter-spacing: 0rem;
        color: #999999; }
    #aboutUs > .centent_box .centent_box_child .centent_box_child_text {
      width: 6.74rem;
      font-size: 0.14rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.32rem;
      letter-spacing: 0rem;
      color: #ffffff;
      margin-top: 0.48rem; }
    #aboutUs > .centent_box .centent_box_child .contact {
      margin-top: 0.6rem;
      font-size: 0.24rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.34rem;
      letter-spacing: 0rem;
      animation-delay: 2s; }
      #aboutUs > .centent_box .centent_box_child .contact a {
        display: block;
        color: #ef875e; }

@media screen and (max-width: 500px) {
  #aboutUs {
    overflow: hidden; }
    #aboutUs > .centent_box {
      width: 19.2rem; }
  #aboutUs > .centent_box .centent_box_child:nth-of-type(1) {
    padding-bottom: 0rem; }
  #aboutUs > .centent_box .centent_box_child {
    padding-left: 0.46rem; }
    #aboutUs > .centent_box .centent_box_child .centent_box_child_text {
      line-height: 1.5;
      font-size: 0.27rem; } }
