/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  vertical-align: baseline; }

img {
  display: block; }

button {
  outline: none; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
  display: block; }

/* unvisited link */
a:link, a:visited, a:hover, a:active {
  color: #ffffff;
  text-decoration: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* custom */
a {
  color: #ffffff;
  text-decoration: none;
  -webkit-backface-visibility: hidden; }

i {
  font-style: normal; }

li {
  list-style: none; }

body {
  width: 100%;
  position: relative;
  font-family: Arial; }

::-webkit-scrollbar {
  display: none; }

.line-clamp-six {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6; }

.progress {
  background: #3c3c3c;
  width: 5.250rem;
  height: 0.333rem;
  overflow: hidden;
  border-radius: 0.167rem;
  -webkit-appearance: none; }

::-ms-fill {
  background: #fafafa; }

::-moz-progress-bar {
  background: #fafafa; }

::-webkit-progress-bar {
  background: #3c3c3c; }

::-webkit-progress-value {
  background: #fafafa;
  border-radius: 0.167rem;
  overflow: hidden; }

.homeLogo {
  width: 100%; }

button {
  border: none;
  white-space: nowrap; }

#solution .swiper-slide {
  position: relative; }
  #solution .swiper-slide .ad {
    position: absolute;
    top: 0.95rem;
    left: 2.93rem; }
    #solution .swiper-slide .ad strong, #solution .swiper-slide .ad span, #solution .swiper-slide .ad button {
      color: #ececec;
      display: block; }
    #solution .swiper-slide .ad strong {
      font-size: 0.3rem;
      margin-bottom: 0.15rem; }
    #solution .swiper-slide .ad span {
      font-size: 0.2rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.25rem;
      letter-spacing: 0rem;
      color: #ececec; }
    #solution .swiper-slide .ad button {
      border-radius: 0.04rem;
      width: 1.32rem;
      min-height: 0.3rem;
      display: block;
      font-size: 0.2rem; }
    #solution .swiper-slide .ad .button_box {
      display: flex;
      margin-top: 0.25rem; }
      #solution .swiper-slide .ad .button_box .applyIntervention {
        background-color: #ffffff;
        color: #fd674d; }
      #solution .swiper-slide .ad .button_box .technicalDocumentation {
        background-color: rgba(0, 0, 0, 0);
        border: solid 0.02rem #ffffff;
        margin-left: 0.4rem; }
  #solution .swiper-slide img {
    width: 100%; }

#solution .technical-solution {
  background-color: #f5f5f5;
  width: 100%;
  height: 3.38rem;
  text-align: center; }
  #solution .technical-solution .title {
    font-size: 0.4rem;
    font-weight: normal;
    font-stretch: normal;
    line-height: 0.5rem;
    letter-spacing: 0rem;
    color: #333333;
    padding: .8rem 0 0.6rem 0; }
  #solution .technical-solution .centent {
    margin: auto;
    width: 8.06rem;
    font-size: 0.2rem;
    font-weight: normal;
    font-stretch: normal;
    line-height: 0.3rem;
    letter-spacing: 0rem;
    color: #333333;
    animation-delay: 0.5s; }

#solution .sdk-list {
  padding: 0.8rem 2rem 0; }
  #solution .sdk-list .sdk-item {
    display: inline-flex;
    width: calc((100% - 3.82rem) / 3);
    flex-flow: column; }
    #solution .sdk-list .sdk-item:nth-of-type(2), #solution .sdk-list .sdk-item:nth-of-type(5) {
      margin: 0 1.9rem; }
    #solution .sdk-list .sdk-item .top {
      position: relative;
      width: 100%;
      height: calc((100% - 3.8rem) / 3);
      overflow: hidden; }
      #solution .sdk-list .sdk-item .top img {
        box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(28, 35, 58, 0.1);
        border-radius: 0.14rem;
        width: 100%;
        height: 100%;
        transition: all 0.6s; }
      #solution .sdk-list .sdk-item .top .text-centent {
        position: absolute;
        bottom: 0;
        width: 100%;
        min-height: 0.67rem;
        background-color: rgba(28, 28, 28, 0.75);
        border-bottom-left-radius: 0.14rem;
        border-bottom-right-radius: 0.14rem;
        padding: 0.12rem 0 0.12rem 0.14rem;
        box-sizing: border-box; }
        #solution .sdk-list .sdk-item .top .text-centent strong, #solution .sdk-list .sdk-item .top .text-centent span {
          display: block; }
        #solution .sdk-list .sdk-item .top .text-centent strong {
          font-size: 0.2rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #fefefe; }
        #solution .sdk-list .sdk-item .top .text-centent span {
          font-size: 0.14rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #ececec; }
    #solution .sdk-list .sdk-item .describe {
      margin: 0.3rem auto; }
    #solution .sdk-list .sdk-item .describe, #solution .sdk-list .sdk-item .apply {
      font-size: 0.14rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.24rem;
      letter-spacing: 0rem;
      color: #333333; }
    #solution .sdk-list .sdk-item button {
      margin-top: 0.3rem;
      margin-bottom: 0.8rem;
      height: 0.5rem !important;
      background-color: #4d4d4d !important;
      color: #ececec !important; }
    #solution .sdk-list .sdk-item .left {
      width: 3.51rem;
      display: flex;
      flex-flow: column; }
      #solution .sdk-list .sdk-item .left .name {
        font-size: 0.26rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.5rem;
        letter-spacing: 0rem;
        color: #333333; }
      #solution .sdk-list .sdk-item .left .describe {
        font-size: 0.16rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        margin: 0.21rem 0 0.28rem;
        color: #999999; }
      #solution .sdk-list .sdk-item .left .centent {
        font-size: 0.14rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.24rem;
        letter-spacing: 0rem;
        color: #333333; }
      #solution .sdk-list .sdk-item .left .apply {
        margin: 0.31rem 0;
        font-size: 0.14rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.24rem;
        letter-spacing: 0rem;
        color: #333333; }
      #solution .sdk-list .sdk-item .left button {
        width: 1.9rem !important;
        height: 0.5rem !important;
        background-color: #4d4d4d !important;
        color: #ececec !important; }
    #solution .sdk-list .sdk-item .right {
      width: 3.79rem;
      height: 3.69rem; }
      #solution .sdk-list .sdk-item .right img {
        width: 100%;
        border-radius: 0.2rem; }

#solution .sdk-list .sdk-item .top img:hover {
  transform: scale(1.2); }

#solution .immediate-advisory-access {
  position: relative;
  width: 100%;
  height: 3.69rem;
  text-align: center;
  overflow: hidden;
  background-size: 100% 100%;
  background-image: url("../../static/solution/Immediate_advisory_access@2x.png");
  background-repeat: no-repeat;
  transition: all 0.6s; }
  #solution .immediate-advisory-access:hover {
    background-size: 101% 101%; }
  #solution .immediate-advisory-access .title {
    margin-top: 1.06rem;
    font-size: 0.3rem;
    font-weight: normal;
    font-stretch: normal;
    line-height: 0.5rem;
    letter-spacing: 0rem;
    color: #fefefe; }
  #solution .immediate-advisory-access .describe {
    font-size: 0.24rem;
    color: #fefefe;
    margin: 0.2rem 0; }
  #solution .immediate-advisory-access .contact button {
    border: none;
    outline: none;
    width: 1.52rem;
    height: 0.5rem;
    background-color: #FE4D5D;
    border-radius: 0.1rem;
    font-size: 0.24rem;
    font-weight: normal;
    font-stretch: normal;
    line-height: 0.36rem;
    letter-spacing: 0rem;
    color: #ececec;
    margin-right: 0.24rem; }
  #solution .immediate-advisory-access .contact span.tel {
    font-size: 0.3rem;
    font-weight: normal;
    font-stretch: normal;
    line-height: 0.36rem;
    letter-spacing: 0rem;
    color: #fefefe; }

@media screen and (max-width: 500px) {
  #solution {
    margin-top: 1.2rem; }
    #solution .swiper-container {
      width: 7.81rem;
      height: 3.27rem; }
      #solution .swiper-container .ad {
        top: 0.8rem;
        left: 0.42rem; }
        #solution .swiper-container .ad span {
          width: 6.11rem;
          line-height: 0.31rem; }
        #solution .swiper-container .ad .button_box button {
          width: 1.88rem;
          height: 0.63rem;
          font-size: 0.21rem; }
      #solution .swiper-container img {
        width: 300%; }
    #solution .technical-solution {
      width: 100%;
      height: auto;
      padding-bottom: 0.2rem; }
      #solution .technical-solution .title {
        font-size: 0.5rem; }
      #solution .technical-solution .centent {
        width: 86%;
        line-height: normal;
        font-size: 0.3rem; }
    #solution .sdk-list {
      padding: 0.8rem 1rem 0; }
      #solution .sdk-list .sdk-item {
        width: 100%;
        margin: 0 !important; }
        #solution .sdk-list .sdk-item .top .text-centent {
          width: 100%; }
          #solution .sdk-list .sdk-item .top .text-centent strong {
            font-size: 0.3rem; }
          #solution .sdk-list .sdk-item .top .text-centent span {
            font-size: 0.21rem; }
        #solution .sdk-list .sdk-item .describe {
          font-size: 0.28rem;
          line-height: normal; }
        #solution .sdk-list .sdk-item .apply {
          font-size: 0.21rem; }
    #solution .immediate-advisory-access {
      width: 100%; }
      #solution .immediate-advisory-access .describe {
        width: 84%;
        margin: auto;
        margin-top: 0.1rem;
        margin-bottom: 0.3rem; }
      #solution .immediate-advisory-access .contact button {
        background: #FD674D; } }
