/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  vertical-align: baseline; }

img {
  display: block; }

button {
  outline: none; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
  display: block; }

/* unvisited link */
a:link, a:visited, a:hover, a:active {
  color: #ffffff;
  text-decoration: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* custom */
a {
  color: #ffffff;
  text-decoration: none;
  -webkit-backface-visibility: hidden; }

i {
  font-style: normal; }

li {
  list-style: none; }

body {
  width: 100%;
  position: relative;
  font-family: Arial; }

::-webkit-scrollbar {
  display: none; }

.line-clamp-six {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6; }

.progress {
  background: #3c3c3c;
  width: 5.250rem;
  height: 0.333rem;
  overflow: hidden;
  border-radius: 0.167rem;
  -webkit-appearance: none; }

::-ms-fill {
  background: #fafafa; }

::-moz-progress-bar {
  background: #fafafa; }

::-webkit-progress-bar {
  background: #3c3c3c; }

::-webkit-progress-value {
  background: #fafafa;
  border-radius: 0.167rem;
  overflow: hidden; }

.homeLogo {
  width: 100%; }

button {
  border: none;
  white-space: nowrap; }

#ProductService .swiper-slide {
  position: relative;
  height: 3rem; }
  #ProductService .swiper-slide .ad {
    position: absolute;
    top: 0.95rem;
    left: 2.93rem; }
    #ProductService .swiper-slide .ad strong, #ProductService .swiper-slide .ad span, #ProductService .swiper-slide .ad button {
      color: #ececec;
      display: block; }
    #ProductService .swiper-slide .ad strong {
      font-size: 0.3rem;
      margin-bottom: 0.15rem; }
    #ProductService .swiper-slide .ad span {
      font-size: 0.2rem;
      line-height: 0.3rem;
      color: #ececec; }
    #ProductService .swiper-slide .ad button {
      border-radius: 0.04rem;
      width: 1.32rem;
      height: 0.3rem;
      display: block;
      font-size: 0.2rem; }
    #ProductService .swiper-slide .ad .button_box {
      display: flex;
      margin-top: 0.25rem; }
      #ProductService .swiper-slide .ad .button_box .applyIntervention {
        background-color: #ffffff;
        color: #fd674d; }
      #ProductService .swiper-slide .ad .button_box .technicalDocumentation {
        background-color: rgba(0, 0, 0, 0);
        border: solid 0.02rem #ffffff;
        margin-left: 0.4rem; }
  #ProductService .swiper-slide img {
    width: 100%; }

#ProductService .ai-experience {
  background-color: #eff3f5;
  padding-bottom: 0.78rem; }
  #ProductService .ai-experience .top-title {
    height: 1.58rem;
    line-height: 1.58rem;
    text-align: center;
    font-size: 0.4rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #333333; }
  #ProductService .ai-experience .ai-centent {
    display: flex;
    justify-content: space-between;
    width: 13.1rem;
    margin: auto; }
    #ProductService .ai-experience .ai-centent .ai-left {
      width: 9.4rem;
      height: 8.38rem;
      background-color: #ffffff;
      box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(28, 35, 58, 0.1); }
      #ProductService .ai-experience .ai-centent .ai-left .ai-left-top {
        width: 100%;
        height: 5.2rem;
        display: flex; }
        #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left {
          width: 8rem;
          height: 5.2rem;
          background-color: #e7e9ee;
          position: relative; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .top_bg_img {
            width: 5.2rem;
            height: 520px;
            margin: auto; }
            #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .top_bg_img .black_head, #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .top_bg_img .dou_dou, #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .top_bg_img .dark_circle {
              position: relative; }
              #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .top_bg_img .black_head span, #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .top_bg_img .dou_dou span, #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .top_bg_img .dark_circle span {
                position: absolute;
                border-radius: 50%; }
            #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .top_bg_img .black_head span {
              background: green; }
            #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .top_bg_img .dou_dou span {
              border: 1px solid red; }
            #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .top_bg_img .dark_circle span {
              border: 2px solid blue;
              border-radius: initial; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .disease_box {
            position: absolute;
            bottom: 0;
            width: 100%;
            display: flex;
            padding: 0.1rem 0;
            background-color: rgba(0, 0, 0, 0.2); }
            #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .disease_box div.disease {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 0.14rem;
              cursor: pointer; }
              #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .disease_box div.disease.active {
                color: #409eff; }
                #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .disease_box div.disease.active span {
                  background-color: #409eff;
                  border-color: #409eff; }
              #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .disease_box div.disease .checkbox_cus {
                display: inline-block;
                position: relative;
                border: 1px solid #dcdfe6;
                background-color: #ffffff;
                border-radius: 2px;
                box-sizing: border-box;
                width: 14px;
                height: 14px;
                margin-right: 0.1rem;
                transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46), background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46); }
                #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .disease_box div.disease .checkbox_cus:after {
                  -webkit-transform: rotate(45deg) scaleY(1);
                  transform: rotate(45deg) scaleY(1);
                  box-sizing: content-box;
                  content: "";
                  border: 1px solid #fff;
                  border-left: 0;
                  border-top: 0;
                  height: 7px;
                  left: 4px;
                  width: 3px;
                  position: absolute;
                  top: 1px; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .loading-box {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0;
            left: 0; }
            #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .loading-box .loading {
              width: 50px;
              height: 50px;
              position: absolute;
              top: 50%;
              left: 50%;
              margin-top: -25px;
              margin-left: -25px; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-left .img {
            height: 100%;
            margin: auto; }
        #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-right {
          flex: 1;
          background-color: #ffffff; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-top .ai-left-top-right .img {
            width: 0.74rem;
            height: 0.74rem;
            border-radius: 0.1rem;
            margin: 1.05rem auto 0;
            border: 0.02rem solid #fd674d; }
      #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom {
        width: 100%;
        height: 3.18rem;
        overflow: hidden;
        background-color: #ffffff; }
        #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-input .input-title {
          margin: 0.39rem 0 0.15rem 0;
          display: block;
          text-indent: 1.49rem;
          font-size: 0.14rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #999999; }
        #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-input .input-box {
          margin: auto;
          width: 6.59rem;
          height: 0.32rem;
          display: flex; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-input .input-box .input {
            display: flex;
            width: 4.64rem;
            border: solid 0.02rem #dadada;
            border-right: none;
            border-radius: 0.06rem; }
            #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-input .input-box .input input {
              flex: 1;
              border: none;
              outline: none;
              text-indent: 0.23rem;
              font-size: 0.14rem;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0rem;
              color: #999999; }
              #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-input .input-box .input input::placeholder {
                color: #999999; }
            #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-input .input-box .input .search {
              width: 0.42rem;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #dadada;
              border-bottom-right-radiusus: 0.06rem;
              border-top-right-radiusus: 0.06rem; }
              #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-input .input-box .input .search img {
                width: 0.18rem; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-input .input-box .button {
            width: 1.35rem;
            margin-left: 0.6rem;
            border: none;
            background-color: #fd674d;
            border-radius: 0.06rem;
            font-size: 0.14rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #fefefe; }
        #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-box {
          padding: 0.38rem 0.7rem 0.2rem 0.7rem;
          display: flex;
          min-height: 0.98rem;
          box-sizing: content-box; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-box.examples {
            justify-content: space-between; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-box.history {
            justify-content: flex-start; }
            #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-box.history img {
              margin-right: calc((100% - 7 * 0.98rem) / 6); }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-box img {
            width: 0.98rem;
            height: 0.98rem;
            display: inline-block; }
            #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-box img:hover, #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-box img.active {
              border: 3px solid #fd674d;
              box-sizing: border-box; }
        #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .examples-history {
          display: flex;
          justify-content: center; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .examples-history span {
            cursor: pointer;
            font-size: 0.2rem;
            font-weight: normal;
            display: inline-block; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .examples-history .active {
            color: #fd674d; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .examples-history .history {
            margin-left: 0.2rem; }
    #ProductService .ai-experience .ai-centent .ai-right {
      width: 3.5rem;
      height: 8.36rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      background-color: #ffffff;
      box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(28, 35, 58, 0.1); }
      #ProductService .ai-experience .ai-centent .ai-right .ai-right-tab {
        display: flex;
        width: 100%; }
        #ProductService .ai-experience .ai-centent .ai-right .ai-right-tab .ai-right-tab-button {
          flex: 1;
          height: 40px;
          line-height: 40px;
          font-size: 0.14rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #333333;
          text-align: center;
          cursor: pointer;
          border-bottom: solid 0.01rem #e4e7ed; }
          #ProductService .ai-experience .ai-centent .ai-right .ai-right-tab .ai-right-tab-button:nth-child(even) {
            border-left: solid 0.01rem #e4e7ed;
            border-right: solid 0.01rem #e4e7ed; }
          #ProductService .ai-experience .ai-centent .ai-right .ai-right-tab .ai-right-tab-button.active {
            color: #fd674d;
            border-bottom: none; }
      #ProductService .ai-experience .ai-centent .ai-right .ai-right-centent {
        flex: 1;
        overflow: auto;
        width: 100%; }
        #ProductService .ai-experience .ai-centent .ai-right .ai-right-centent .li {
          margin: 0.06rem .136rem 0;
          height: 0.36rem;
          background-color: #f4f8fc;
          border-radius: 0.04rem;
          display: flex; }
          #ProductService .ai-experience .ai-centent .ai-right .ai-right-centent .li span {
            flex: 1; }
          #ProductService .ai-experience .ai-centent .ai-right .ai-right-centent .li .ai-right-centent-li-name {
            font-size: 0.14rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #333333;
            text-align: left; }
            #ProductService .ai-experience .ai-centent .ai-right .ai-right-centent .li .ai-right-centent-li-name i {
              width: 0.08rem;
              height: 0.08rem;
              display: inline-block;
              border-radius: 50%;
              background-color: #fd674d;
              margin-right: 0.06rem; }
          #ProductService .ai-experience .ai-centent .ai-right .ai-right-centent .li .ai-right-centent-li-value {
            font-size: 0.14rem;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0rem;
            color: #777777;
            display: inline-flex;
            align-items: flex-end;
            justify-content: flex-end; }
      #ProductService .ai-experience .ai-centent .ai-right .ai-right-copy {
        color: #fd674d;
        margin: 5px;
        border-color: #fd674d; }

#ProductService .access-service {
  padding-bottom: 0.8rem; }
  #ProductService .access-service .top-title {
    height: 1.78rem;
    line-height: 1.78rem;
    text-align: center;
    font-size: 0.4rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #333333; }
  #ProductService .access-service .bottom-centent {
    display: flex;
    justify-content: space-between;
    width: 13rem;
    margin: auto; }
    #ProductService .access-service .bottom-centent .bottom-item {
      width: 6.38rem;
      box-sizing: border-box;
      background-color: #ffffff;
      box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(28, 35, 58, 0.1);
      padding: .31rem 0.28rem .26rem .28rem; }
      #ProductService .access-service .bottom-centent .bottom-item .name-icon {
        display: flex;
        align-items: center; }
        #ProductService .access-service .bottom-centent .bottom-item .name-icon img {
          transition: all 0.4s; }
        #ProductService .access-service .bottom-centent .bottom-item .name-icon .img {
          width: 0.3rem;
          display: inline-block; }
        #ProductService .access-service .bottom-centent .bottom-item .name-icon .hover-img {
          transform: rotate(360deg); }
        #ProductService .access-service .bottom-centent .bottom-item .name-icon .name, #ProductService .access-service .bottom-centent .bottom-item .name-icon .name-right {
          margin-left: 0.1rem;
          font-size: 0.2rem;
          transition: all 0.6s; }
        #ProductService .access-service .bottom-centent .bottom-item .name-icon .name-right {
          margin-left: 0.2rem; }
      #ProductService .access-service .bottom-centent .bottom-item .centent {
        color: #999;
        font-size: 0.14rem;
        margin: 0.17rem auto 0.38rem; }
      #ProductService .access-service .bottom-centent .bottom-item .buttons {
        display: flex; }
        #ProductService .access-service .bottom-centent .bottom-item .buttons button {
          width: 1.18rem;
          display: block;
          height: 0.4rem;
          border-radius: 0.04rem;
          border: solid 1px #fd674d;
          background-color: rgba(0, 0, 0, 0);
          color: #fd674d; }
        #ProductService .access-service .bottom-centent .bottom-item .buttons a:nth-of-type(1) button {
          color: #ffffff;
          margin-right: 0.38rem;
          background-color: #fd674d; }

#ProductService .access-process {
  padding-bottom: 0.8rem;
  background-color: #f4f5f7; }
  #ProductService .access-process .top-title {
    height: 1.77rem;
    line-height: 1.77rem;
    text-align: center;
    font-size: 0.4rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #333333; }

@media screen and (max-width: 500px) {
  #ProductService {
    margin-top: 1.2rem; }
    #ProductService .swiper-container {
      width: 7.81rem;
      height: 3.27rem; }
      #ProductService .swiper-container .ad {
        top: 0.8rem;
        left: 0.42rem; }
        #ProductService .swiper-container .ad span {
          width: 6.11rem;
          line-height: 0.31rem; }
        #ProductService .swiper-container .ad .button_box button {
          width: 1.88rem;
          height: 0.63rem;
          font-size: 0.21rem; }
      #ProductService .swiper-container img {
        width: 300%; }
    #ProductService .ai-experience .ai-centent {
      width: 100%;
      flex-flow: column; }
      #ProductService .ai-experience .ai-centent .ai-left, #ProductService .ai-experience .ai-centent .ai-right {
        width: 100%; }
      #ProductService .ai-experience .ai-centent .ai-left {
        height: auto; }
        #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom {
          height: auto; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .examples-history {
            padding-bottom: 10px; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .placeholder {
            height: 0.18rem;
            background-color: #e7e9ee; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-input .input-title {
            font-size: 0.27rem;
            text-indent: 0;
            text-align: center; }
          #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-input .input-box {
            height: 0.63rem; }
            #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-input .input-box .input .search {
              width: 0.59rem;
              height: 0.59rem; }
              #ProductService .ai-experience .ai-centent .ai-left .ai-left-bottom .img-input .input-box .input .search img {
                width: 0.25rem; }
      #ProductService .ai-experience .ai-centent .ai-right .ai-right-centent .li {
        padding: 0 10%;
        height: 0.89rem;
        line-height: 0.89rem; }
        #ProductService .ai-experience .ai-centent .ai-right .ai-right-centent .li .ai-right-centent-li-name i {
          width: 0.21rem;
          height: 0.21rem; }
        #ProductService .ai-experience .ai-centent .ai-right .ai-right-centent .li .ai-right-centent-li-name, #ProductService .ai-experience .ai-centent .ai-right .ai-right-centent .li .ai-right-centent-li-value {
          font-size: 0.28rem; }
      #ProductService .ai-experience .ai-centent .ai-right .ai-right-tab .ai-right-tab-button {
        height: 0.8rem;
        line-height: 0.8rem;
        font-size: 0.28rem; }
    #ProductService .access-service .bottom-centent {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center; }
      #ProductService .access-service .bottom-centent .bottom-item:nth-of-type(1) {
        margin-bottom: 0.2rem; }
      #ProductService .access-service .bottom-centent .bottom-item .buttons button {
        width: 2.2rem;
        height: 0.8rem; }
      #ProductService .access-service .bottom-centent .bottom-item .name-icon .name {
        font-size: 0.34rem; }
      #ProductService .access-service .bottom-centent .bottom-item .centent {
        font-size: 0.238rem; }
    #ProductService .access-process .access-guide-img {
      width: 88%; }
    #ProductService .access-process .access-guide-bottom-centent {
      width: 100%; } }
