/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  vertical-align: baseline; }

img {
  display: block; }

button {
  outline: none; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
  display: block; }

/* unvisited link */
a:link, a:visited, a:hover, a:active {
  color: #ffffff;
  text-decoration: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* custom */
a {
  color: #ffffff;
  text-decoration: none;
  -webkit-backface-visibility: hidden; }

i {
  font-style: normal; }

li {
  list-style: none; }

body {
  width: 100%;
  position: relative;
  font-family: Arial; }

::-webkit-scrollbar {
  display: none; }

.line-clamp-six {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6; }

.progress {
  background: #3c3c3c;
  width: 5.250rem;
  height: 0.333rem;
  overflow: hidden;
  border-radius: 0.167rem;
  -webkit-appearance: none; }

::-ms-fill {
  background: #fafafa; }

::-moz-progress-bar {
  background: #fafafa; }

::-webkit-progress-bar {
  background: #3c3c3c; }

::-webkit-progress-value {
  background: #fafafa;
  border-radius: 0.167rem;
  overflow: hidden; }

.homeLogo {
  width: 100%; }

button {
  border: none;
  white-space: nowrap; }

#openPlatform .swiper-slide {
  position: relative;
  height: 3rem;
  overflow: hidden; }
  #openPlatform .swiper-slide .ad {
    position: absolute;
    top: 1.16rem;
    width: 100%;
    text-align: center; }
    #openPlatform .swiper-slide .ad strong, #openPlatform .swiper-slide .ad span {
      display: block;
      font-weight: normal;
      letter-spacing: 0rem;
      color: #ececec; }
    #openPlatform .swiper-slide .ad strong {
      font-size: 0.36rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.5rem; }
    #openPlatform .swiper-slide .ad span {
      font-size: 0.20rem; }
    #openPlatform .swiper-slide .ad button {
      background-color: #fd674d !important;
      color: #ececec !important;
      border: none;
      margin: 0.15rem auto 0; }
  #openPlatform .swiper-slide img {
    width: 100%; }

#openPlatform .openPlatform-centent {
  display: flex; }
  #openPlatform .openPlatform-centent .left {
    width: 2.72rem;
    min-height: 11.2rem;
    background-color: #111321;
    padding-top: 0.3rem; }
    #openPlatform .openPlatform-centent .left .tab-item {
      padding: 0.2rem 0 0 0.4rem; }
      #openPlatform .openPlatform-centent .left .tab-item .tab-item-name {
        cursor: pointer;
        font-size: 0.14rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.36rem;
        letter-spacing: 0rem;
        color: #fefefe; }
        #openPlatform .openPlatform-centent .left .tab-item .tab-item-name.active {
          color: #fd674d; }
          #openPlatform .openPlatform-centent .left .tab-item .tab-item-name.active > a {
            color: #fd674d; }
          #openPlatform .openPlatform-centent .left .tab-item .tab-item-name.active img {
            animation-duration: .6s;
            animation-name: rotating;
            transform: rotate(-180deg); }

@keyframes rotating {
  from {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg); }
  to {
    transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg); } }
        #openPlatform .openPlatform-centent .left .tab-item .tab-item-name .tab-item-name a {
          color: #B0B0B0; }
        #openPlatform .openPlatform-centent .left .tab-item .tab-item-name img {
          width: 0.08rem;
          height: 0.05rem;
          float: right;
          margin-right: 0.32rem;
          margin-top: 0.155rem; }
  #openPlatform .openPlatform-centent .left-fixed {
    position: fixed;
    top: 69px; }
  #openPlatform .openPlatform-centent .right-item {
    color: #3c3c3c;
    margin-left: 0.49008rem;
    margin-right: 0.49008rem;
    margin-top: 0.48rem;
    padding-bottom: 1.6rem;
    flex: 1;
    font-size: 0.14rem; }
    #openPlatform .openPlatform-centent .right-item .title {
      font-size: 0.2rem; }
    #openPlatform .openPlatform-centent .right-item .top-title {
      font-size: 0.25rem;
      font-weight: bold; }
    #openPlatform .openPlatform-centent .right-item hr {
      width: 10.93008rem;
      height: 0.02rem;
      background-color: #d6d7db;
      margin: 0.21008rem 0 0 0; }
    #openPlatform .openPlatform-centent .right-item .test-address {
      line-height: 0.375rem;
      font-size: 0.0875rem;
      margin: 0.32rem 0 0.2rem; }
    #openPlatform .openPlatform-centent .right-item table, #openPlatform .openPlatform-centent .right-item table tr th, #openPlatform .openPlatform-centent .right-item table tr td {
      border: 0.01008rem solid #d6d7db; }
    #openPlatform .openPlatform-centent .right-item .test-table {
      width: 100%;
      margin: 0.32rem 0 0.2rem; }
      #openPlatform .openPlatform-centent .right-item .test-table tbody tr {
        color: #3c3c3c; }
      #openPlatform .openPlatform-centent .right-item .test-table th {
        padding: 0 0.25rem;
        text-align: left; }
      #openPlatform .openPlatform-centent .right-item .test-table td {
        padding: 0.1rem 0.25rem; }
    #openPlatform .openPlatform-centent .right-item pre {
      color: #3c3c3c;
      font-size: 0.14rem; }
  #openPlatform .openPlatform-centent .right {
    flex: 1;
    margin-top: 0.3rem;
    padding: 0 0.73rem 0 0.37rem; }
    #openPlatform .openPlatform-centent .right .title {
      font-size: 0.3rem;
      font-weight: bold;
      font-stretch: normal;
      line-height: 0.36rem;
      letter-spacing: 0rem;
      color: #333333;
      padding: 0.08rem 0;
      border-bottom: solid 0.01rem #999999; }
    #openPlatform .openPlatform-centent .right .top .title {
      font-size: 0.25rem;
      font-weight: bold;
      border-bottom: none; }
    #openPlatform .openPlatform-centent .right .top .access-guide-bottom-centent {
      margin: 0.29rem auto 0;
      width: 13.56rem;
      display: flex; }
    #openPlatform .openPlatform-centent .right .top .access-guide-img {
      margin-top: 0.8rem; }
    #openPlatform .openPlatform-centent .right .bottom-centent {
      padding-top: 0.8rem; }
      #openPlatform .openPlatform-centent .right .bottom-centent .bottom-item {
        padding-bottom: 0.82rem; }
        #openPlatform .openPlatform-centent .right .bottom-centent .bottom-item .centent {
          font-size: 0.14rem;
          font-weight: normal;
          font-stretch: normal;
          line-height: 0.36rem;
          letter-spacing: 0rem;
          color: #777777;
          margin-top: 0.07rem; }
        #openPlatform .openPlatform-centent .right .bottom-centent .bottom-item .centent-img img {
          width: 70%;
          margin-bottom: 20px; }

@media screen and (max-width: 500px) {
  #openPlatform {
    margin-top: 1.2rem; }
    #openPlatform .swiper-container {
      width: 7.81rem;
      height: 3.27rem; }
      #openPlatform .swiper-container .ad {
        top: 0.8rem; }
        #openPlatform .swiper-container .ad span {
          line-height: 0.31rem; }
        #openPlatform .swiper-container .ad button {
          width: 1.8rem;
          height: 0.6rem; }
      #openPlatform .swiper-container img {
        width: 300%; } }

#openPlatform .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent; }

#openPlatform .ant-menu-dark .ant-menu-item-selected > a, #openPlatform .ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #FD674D; }
