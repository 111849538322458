
  .access-guide-img{
    width: 11.42rem;
    margin: auto;
  }
  .access-guide-bottom-centent{
    //display: flex;
    //justify-content: space-between;
    //width: 10.68rem;
    margin: 0.29rem auto 0;
    width: 13.56rem;
    display: flex;
    //margin: auto;
    .bottom-item{
      flex: 1;
      display: inline-block;
      text-align: center;
      &:nth-of-type(1){
        .name {
          animation-delay: 0.5s;
        }
      }
      &:nth-of-type(2){
        margin-left: 0.6rem;
        .name {
          animation-delay: 1s;
        }
      }
      &:nth-of-type(3){
        margin-left: 0.26rem;
        .name {
          animation-delay: 1.5s;
        }
      }
      &:nth-of-type(4){
        margin-left: 0.47rem;
        .name {
          animation-delay: 2s;
        }
      }
      .name{
        display: block;
        font-size: 0.2rem;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #333333;
        margin-bottom: 0.14rem;
      }
      .centent{
        font-size: 0.18rem;
        font-weight: normal;
        font-stretch: normal;
        //line-height: 0.36rem;
        letter-spacing: 0rem;
        color: #777;
      }
    }
  }
//}
